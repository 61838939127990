import {Container, Paper} from "@mui/material";
import React, {Component} from 'react';
import {inject, observer} from "mobx-react";
import OnboardingApp from "./OnboardingApp";
import OnboardingCode from "./OnboardingCode";
import CommsLog from "./CommsLog";
import FcmToken from "./FcmToken";
import Authorize from "./Authorize";
import Unregister from "./Unregister";
import PasswordDialog from "./PasswordDialog";
import UserStateCheck from "./UserStateCheck";
import KrrInfo from "./KrrInfo";
import NewPassword from "./NewPassword";
import Order from "./Order";
import DirectOnboarding from "./DirectOnboarding";

class App extends Component {

    render() {
        return (
                <Container sx={{pb: "1em", fontFamily: "Roboto"}} component="main">
                    <Paper elevation={8} sx={{pb: "1em"}}>
                        <h1 style={{textAlign: "center", margin: "0.5em"}}>Notification Server API client</h1>
                        <FcmToken/>
                        <PasswordDialog/>
                        <UserStateCheck/>
                        <OnboardingApp />
                        <OnboardingCode />
                        <NewPassword />
                        <Order />
                        <DirectOnboarding />
                        <Authorize />
                        <Unregister/>
                        <KrrInfo/>
                        <CommsLog />
                    </Paper>
            </Container>
        );
    }
}

export default inject('serverStore')(observer(App));
