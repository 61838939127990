import React, {Component} from 'react';
import {inject, observer} from "mobx-react";
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import SynchedInput from "./SynchedInput";
import {IconButton, InputAdornment, Slide} from "@mui/material";
import {Visibility, VisibilityOff} from "@mui/icons-material";
import {css, keyframes} from '@mui/system';
import styled from "@emotion/styled";
import DigdirForm from "./DigdirForm";
import {isEmpty} from "lodash";

const spin = keyframes`
  0% { transform: translate(1px, 1px) rotate(0deg); }
  10% { transform: translate(-1px, -2px) rotate(-1deg); }
  20% { transform: translate(-3px, 0px) rotate(1deg); }
  30% { transform: translate(3px, 2px) rotate(0deg); }
  40% { transform: translate(1px, -1px) rotate(1deg); }
  50% { transform: translate(-1px, 2px) rotate(-1deg); }
  60% { transform: translate(-3px, 1px) rotate(0deg); }
  70% { transform: translate(3px, 1px) rotate(-1deg); }
  80% { transform: translate(-1px, -1px) rotate(1deg); }
  90% { transform: translate(1px, 2px) rotate(-1deg); }
  100% { transform: translate(0px, 0px) rotate(0deg); }
`;


const ShakeWrapper = styled("div")(
    ({ theme, animate  }) => css`
    animation: ${animate ? css`${spin} 0.82s cubic-bezier(.36,.07,.19,.97) both` : "none"}; 
`);

const SlideTransition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

class PasswordDialog extends Component {

    state = {
        showPassword: false
    };

    decryptConfig() {
        this.props.firebaseStore.wrongPasskey = false;
        this.props.firebaseStore.decryptConfig();
    }

    toggleShowPassword() {
        this.setState({
            showPassword: !this.state.showPassword
        });
    }

    render() {
        const {firebaseStore} = this.props;
        const {vapid, firebaseConfig} = firebaseStore;
        const openDialog = (!vapid) || (isEmpty(firebaseConfig));

        return (
            <div>
                <Dialog open={openDialog} keepMounted TransitionComponent={SlideTransition} >
                    <DigdirForm id="decrypt-form" onSubmitCallback={this.decryptConfig.bind(this)}>
                    <DialogTitle>Decrypt config</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            Enter password to decrypt config (same password as for oidc-client in TEST1).
                        </DialogContentText>


                        <ShakeWrapper animate={firebaseStore.wrongPasskey ? 1 : 0}>
                            <SynchedInput  id="passkey" autoFocus fullWidth autoComplete="current-password" variant="outlined" margin="dense" source={firebaseStore} path="passkey" required label="Password"
                                          type={this.state.showPassword ? 'text' : 'password'}
                                          InputProps={{
                                              endAdornment:
                                                  <InputAdornment position="end" >
                                                      <IconButton
                                                          aria-label="toggle password visibility"
                                                          onClick={this.toggleShowPassword.bind(this)}
                                                          edge="end"
                                                      >
                                                      {this.state.showPassword ? <VisibilityOff/> : <Visibility/>}
                                                      </IconButton>
                                                  </InputAdornment>
                                          }}
                                          onKeyPress={(ev) => {
                                              if (ev.key === 'Enter') {
                                                  this.decryptConfig();
                                                  ev.preventDefault();
                                              }
                                          }}
                            />
                        </ShakeWrapper>
                    </DialogContent>
                    <DialogActions>
                        <Button  form="decrypt-form" type="submit" onClick={this.decryptConfig.bind(this)}>Submit</Button>
                    </DialogActions>
                </DigdirForm>
                </Dialog>
            </div>
        );
    }
}

export default inject('firebaseStore')(observer(PasswordDialog));
