import {inject, observer} from "mobx-react";
import React, {Component} from "react";
import TitleBox from "./TitleBox";
import DigdirForm from "./DigdirForm";
import SynchedInput from "./SynchedInput";
import CircleIcon from "@mui/icons-material/Circle";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorIcon from "@mui/icons-material/Error";
import {LoadingButton} from "@mui/lab";
import styled from "@emotion/styled";

const WrappingGrid = styled('div')(
    ({ theme }) => `
        display: grid;
        grid-template-columns: 5fr 1fr;
        row-gap: 0.5em;
        align-items: center;
`,);

const InnerGrid = styled('div')(
    ({ theme }) => `
        display: grid;
        grid-template-columns: 4fr 1fr;
        grid-template-rows: auto;
        column-gap: 0.5em;
        align-items: center;
`,);

const StatusDiv = styled('div')(
    ({ theme }) => `
        display: grid;
        grid-column: 2/2;
        grid-row: span 2;
        margin-left: 0.5em;        
        grid-template-columns: 1fr 1fr;
        grid-template-rows: auto;
        align-items: center;
`,);



class NewPassword extends Component {

    onSubmitNewPasswordStart() {
        this.props.serverStore.newPasswordStart();
    }

    onSubmitNewPasswordContinue() {
        this.props.serverStore.newPasswordContinue();
    }

    onSubmitNewPasswordFinalize() {
        this.props.serverStore.newPasswordFinalize();
    }

    getStatusIcon(status) {
        return (
            <span >
            {(status === "unknown")
                ? <CircleIcon color="primary" sx={{verticalAlign: "middle"}} />
                : (status === "ok")
                    ? <CheckCircleIcon color="success" sx={{verticalAlign: "middle"}} />
                    : <ErrorIcon color="error" sx={{verticalAlign: "middle"}} />
            }
            </span>
        );
    }

    render() {
        const {serverStore} = this.props;
        const {token} = this.props.firebaseStore;

        const startStatusIcon = this.getStatusIcon(serverStore.start_new_password_status);
        const continueStatusIcon = this.getStatusIcon(serverStore.continue_new_password_status);
        const finalizeStatusIcon = this.getStatusIcon(serverStore.finalize_new_password_status);

        return (
            <TitleBox label="New Password">

                <WrappingGrid>

                    <DigdirForm id="new-password-start-form" onSubmitCallback={this.onSubmitNewPasswordStart.bind(this)}>
                        <InnerGrid>
                            <SynchedInput id="code-person-identifier" source={serverStore} path="person_identifier" inputProps={{inputMode: 'numeric', pattern: '[0-9]{11}'}} required label="Person identifier"/>
                            <LoadingButton id="submit-code" loading={serverStore.loading_new_password_start} variant="contained" disabled={!token} label="Submit" form="new-password-start-form" type="submit" >Submit</LoadingButton>
                        </InnerGrid>
                    </DigdirForm>

                    <StatusDiv>
                        <div id="start-status">start:</div> <div>{startStatusIcon}</div>
                        <div id="continue-status">continue:</div> <div>{continueStatusIcon}</div>
                        <div id="finalize-status">finalize: </div> <div>{finalizeStatusIcon}</div>
                    </StatusDiv>

                    <DigdirForm id="new-password-sms-continue-form" onSubmitCallback={this.onSubmitNewPasswordContinue.bind(this)}>
                        <InnerGrid>
                            <SynchedInput id="otc" source={serverStore} path="otc" required label="One-time-code SMS / Email"/>
                            <LoadingButton id="submit-otc" loading={serverStore.loading_new_password_continue} variant="contained" label="Submit" form="new-password-sms-continue-form" type="submit" disabled={(serverStore.start_new_password_status !== "ok")}>Submit</LoadingButton>
                        </InnerGrid>
                    </DigdirForm>

                    <DigdirForm id="new-password-finalize-form" onSubmitCallback={this.onSubmitNewPasswordFinalize.bind(this)}>
                        <InnerGrid>
                            <SynchedInput id="password" source={serverStore} path="password" required label="Password"/>
                            <LoadingButton id="submit-passwd" loading={serverStore.loading_new_password_finalize} variant="contained" disabled={(serverStore.continue_new_password_status !== "ok")} label="Submit" form="new-password-finalize-form" type="submit">Submit</LoadingButton>
                        </InnerGrid>
                    </DigdirForm>

                </WrappingGrid>

            </TitleBox>
        );
    }

}

export default inject('serverStore', 'firebaseStore')(observer(NewPassword));
