import React, {Component} from "react";
import {observer} from "mobx-react";

class DigdirForm extends Component {


    onSubmit(e) {
        e.preventDefault();
        const {onSubmitCallback} = this.props;
        if(onSubmitCallback) {
            onSubmitCallback(e);
        }
    }

    render() {
        const { children, onSubmitCallback, ...props } = this.props;

        return (
            <form ref={form => this.formRef = form}
                  onSubmit={this.onSubmit.bind(this)}
                  sx={{
                      '& .MuiButtonBase-root': {
                          paddingTop: "7px",
                          paddingBottom: "7px"
                      }
                  }}
                  {...props}
            >
                {children}
            </form>

        );
    }
}

export default observer(DigdirForm);
