import {inject, observer} from "mobx-react";
import React, {Component} from "react";
import TitleBox from "./TitleBox";
import {LoadingButton} from "@mui/lab";

class Authorize extends Component {

    render() {

        const {serverStore} = this.props;
        const serviceName = this.props.serverStore.service_name;

        return (
            <TitleBox  label="Authorize login attempt">
                <div>Approve or reject login attempt to service: {serviceName}</div>
                <div style={{textAlign: "center"}}>
                    <LoadingButton id="approve" loading={serverStore.loading_authorize_approve} sx={{margin: "1em"}} variant="contained" disabled={!serviceName} onClick={() => {serverStore.authorizeLogin(true)}}>Approve</LoadingButton>
                    <LoadingButton id="reject" loading={serverStore.loading_authorize_reject} sx={{margin: "1em"}} variant="contained" disabled={!serviceName} onClick={() => {serverStore.authorizeLogin(false)}}>Reject</LoadingButton>
                </div>
            </TitleBox>
        );
    }

}

export default inject('serverStore')(observer(Authorize));

