import React, {Component} from 'react';
import {inject, observer} from "mobx-react";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import {CopyToClipboard} from "react-copy-to-clipboard/lib/Component";

class FcmToken extends Component {
    state = {
        copied_token: false,
    };

    getShortToken(token) {
        return (token)
            ? token.substring(0, 10)  + "..." + token.substring(token.length - 10)
            : "";
    }

    render() {
        const {token} = this.props.firebaseStore;
        const {device_status} = this.props.serverStore;
        const {order_state} = this.props.serverStore;

        const shortToken =  this.getShortToken(token);

        return (
            <div style={{textAlign: "center"}}>
                <div>
                    FCM token: {shortToken && <span id="fcm_token">{shortToken}</span>}
                    <CopyToClipboard text={token} onCopy={() => this.setState({copied_token: true})}>
                        <ContentCopyIcon sx={{fontSize: 18, verticalAlign: "bottom", marginLeft: "0.5em"}} />
                    </CopyToClipboard>
                    {this.state.copied_token ? <span style={{color: 'red'}}> Copied to clipboard</span> : null}
                </div>
                <div>Device status: {device_status}</div>
                <div>Order status: {order_state}</div>
            </div>);
        }
}

export default inject('firebaseStore', 'serverStore')(observer(FcmToken));
