import {inject, observer} from "mobx-react";
import React, {Component} from "react";
import TitleBox from "./TitleBox";
import DigdirForm from "./DigdirForm";
import SynchedInput from "./SynchedInput";
import CircleIcon from "@mui/icons-material/Circle";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorIcon from "@mui/icons-material/Error";
import {LoadingButton} from "@mui/lab";
import styled from "@emotion/styled";

const WrappingGrid = styled('div')(
    ({ theme }) => `
        display: grid;
        grid-template-columns: 5fr 1fr;
        row-gap: 0.5em;
        align-items: center;
`,);

const InnerGrid = styled('div')(
    ({ theme }) => `
        display: grid;
        grid-template-columns: 2fr 2fr 1fr;
        grid-template-rows: auto;
        column-gap: 0.5em;
        align-items: center;
`,);

const StatusDiv = styled('div')(
    ({ theme }) => `
        display: grid;
        grid-column: 2/2;
        grid-row: span 2;
        margin-left: 0.5em;        
        grid-template-columns: 1fr 1fr;
        grid-template-rows: auto;
        align-items: center;
`,);

const ActivationCodeText = styled('div')(
    ({ theme }) => `
        font-family: Roboto, Helvetica, Arial, sans-serif;
        font-size: 16px;
        font-stretch: 100%;
        font-style: normal;
        font-weight: 400;
        color: grey;
        margin-left: 1em;
        margin-top: 0.5em;
`,);

class Order extends Component {

    onSubmitOrderStart() {
        this.props.serverStore.orderStart();
    }

    onSubmitOrderFinalize() {
        //this.props.serverStore.orderFinalize();
    }

    getStatusIcon(status) {
        return (
            <span >
            {(status === "unknown")
                ? <CircleIcon color="primary" sx={{verticalAlign: "middle"}} />
                : (status === "ok")
                    ? <CheckCircleIcon color="success" sx={{verticalAlign: "middle"}} />
                    : <ErrorIcon color="error" sx={{verticalAlign: "middle"}} />
            }
            </span>
        );
    }

    render() {
        const {serverStore} = this.props;
        const {token} = this.props.firebaseStore;

        const startStatusIcon = this.getStatusIcon(serverStore.start_order_status);
        const finalizeStatusIcon = this.getStatusIcon(serverStore.finalize_order_status);

        return (
            <TitleBox label="Order">

                <WrappingGrid>

                    <DigdirForm id="order-start-form" onSubmitCallback={this.onSubmitOrderStart.bind(this)}>
                        <InnerGrid>
                            <SynchedInput id="order-person-identifier" source={serverStore} path="person_identifier" inputProps={{inputMode: 'numeric', pattern: '[0-9]{11}'}} required label="Person identifier"/>
                            <ActivationCodeText>
                                Activation code: {serverStore.activation_code && <span id="activation_code">{serverStore.activation_code}</span>}
                            </ActivationCodeText>
                            <LoadingButton id="submit-code" loading={serverStore.loading_order_start} variant="contained" disabled={!token} label="Submit" form="order-start-form" type="submit" >Submit</LoadingButton>
                        </InnerGrid>
                    </DigdirForm>

                    <StatusDiv>
                        <div id="start-status">start:</div> <div>{startStatusIcon}</div>
                        <div id="finalize-status">finalize: </div> <div>{finalizeStatusIcon}</div>
                    </StatusDiv>

                </WrappingGrid>

            </TitleBox>
        );
    }

}

export default inject('serverStore', 'firebaseStore')(observer(Order));