import {makeAutoObservable} from "mobx";


export class LogObject {
    title = "";
    json = {};

    constructor(data) {

        makeAutoObservable(this);

        if (typeof data === "undefined") {
            return;
        }
        this.title = data.title;
        this.json = data.json;
    }

}
