import {action, makeAutoObservable} from "mobx";

import axios from "axios";
import {LogObject} from "./domain/LogObject";
import {OnboardingAppContinue, OnboardingAppFinalize, OnboardingAppStart} from "./domain/OnboardingAppObject";
import {OnboardingCodeFinalize, OnboardingCodeStart} from "./domain/OnboardingCodeObject";
import {NewPasswordContinue, NewPasswordFinalize, NewPasswordStart} from "./domain/NewPasswordObject";
import {
    DirectOnboardingContinue,
    DirectOnboardingFinalize,
    DirectOnboardingStart
} from "./domain/DirectOnboardingObject";
import {CheckStateFinalize, CheckStateStart} from "./domain/CheckState";
import {OrderFinalize, OrderStart} from "./domain/OrderObject";


const API_BASE_URL = process.env.NODE_ENV === "development" ? "http://localhost:8080" : "";

const status = {
    UNKNOWN: "unknown",
    OK: "ok",
    FAILED: "failed"
}

export default class ServerStore {

    commslog = []; // Array of LogObjects
    person_identifier = "";
    password = "";
    otc = "";
    two_factor_method = ""; // PIN / SMS

    code = ""; // activation letter code
    email = "";
    mobile = "";
    login_key = "";

    pin_code_index = "";

    pin1 = "";
    pin2 = "";

    start_status = status.UNKNOWN;
    continue_status = status.UNKNOWN;
    finalize_status = status.UNKNOWN;

    start_code_status = status.UNKNOWN;
    finalize_code_status = status.UNKNOWN;

    start_new_password_status = status.UNKNOWN;
    continue_new_password_status = status.UNKNOWN;
    finalize_new_password_status = status.UNKNOWN;

    start_direct_onboarding_status = status.UNKNOWN;
    continue_direct_onboarding_status = status.UNKNOWN;
    finalize_direct_onboarding_status = status.UNKNOWN;

    start_order_status = status.UNKNOWN;
    finalize_order_status = status.UNKNOWN;

    state_check_start_status = status.UNKNOWN;
    state_check_finalize_status = status.UNKNOWN;

    device_status = "UNKNOWN";
    order_state = "FALSE";

    login_attempt_id = "";
    login_attempt_counter = "";
    service_name = "";

    user_state = "";
    activation_code = "";

    loading_app_start = false;
    loading_app_finalize = false;

    loading_code_start = false;
    loading_code_finalize = false;

    loading_direct_onboarding_start = false;
    loading_direct_onboarding_continue = false;
    loading_direct_onboarding_finalize = false;

    loading_authorize_approve = false;
    loading_authorize_reject = false;

    loading_new_password_start = false
    loading_new_password_continue = false
    loading_new_password_finalize = false

    loading_order_start = false;

    loading_state_check_start = false;

    loading_unregister = false;

    loading_krrinfo = false;
    krrinfo_status = status.UNKNOWN;
    krrinfo = {};

    constructor(rootStore) {
        this.rootStore = rootStore;
        makeAutoObservable(this, {
            onMessageReceived: action.bound,
            addLog: action.bound,
            authorizeLogin: action.bound,
            unregisterDevice: action.bound,
            appOnboardingFinalize: action.bound,
            appOnboardingContinue: action.bound,
            appOnboardingStart: action.bound,
            logError: action.bound,
            loadKrrInfo: action.bound,
            checkStatus: action.bound
        });
    }


    getFirebaseToken() {
        return this.rootStore.firebaseStore.token;
    }

    messages_received = [];

    onMessageReceived(payloadData, msgSource) {

        const message_id = payloadData.message_id;
        this.addLog(msgSource + " received, id " + message_id , payloadData);

        if(this.messages_received.includes(message_id)) {
            this.addLog("Message id " + message_id + " already handled. Skipping.", {});
            return;
        }

        this.messages_received.push(message_id);

        switch (payloadData.category) {
            case "onboardingCategory":
                this.login_key = payloadData.login_key;
                this.appOnboardingContinue(payloadData.login_key);
                break;
            case "authCategory":
                this.service_name = payloadData.service_name;
                this.login_attempt_id = payloadData.login_attempt_id;
                this.login_attempt_counter = payloadData.login_attempt_counter;
                break;
            case "activationCodeOnboardingCategory":
                this.login_key = payloadData.login_key;
                break;
            case "userStateCheckCategory":
                this.checkStateFinalize(payloadData.login_key);
                break;
            case "newPasswordCategory":
                this.login_key = payloadData.login_key;
                break;
            case "orderCategory":
                this.login_key = payloadData.login_key;
                this.orderFinalize();
                break;
            case "directOnboardingCategory":
                this.login_key = payloadData.login_key;
                this.directOnboardingContinue(payloadData.login_key);
                break;
            default:
                console.log("Unknown category in payload: ", payloadData.category);
        }
    }

    addLog(title, json) {
        this.commslog.unshift(new LogObject({title, json}));
    }

    authorizeLogin(approve) {
        const data = {
            login_attempt_id: this.login_attempt_id,
            login_attempt_counter: this.login_attempt_counter
        }

        if (approve) {
            this.loading_authorize_approve = true;
        } else {
            this.loading_authorize_reject = true;
        }
        axios.post(API_BASE_URL + "/api/v2/authorization/" + (approve ? "approve" : "reject"), data, {headers: {Authorization: `AppToken ${this.getFirebaseToken()}`}})
            .then((response) => {
                this.addLog("Authorize login status: " + response.status, {});
            })
            .catch(err => {
                this.logError("Authorize login attempt error", err);
                return Promise.reject(err.response);
            })
            .then(() => {
                this.login_attempt_counter = "";
                this.login_attempt_id = "";
                this.service_name = "";
            })
            .finally(() => {
                this.loading_authorize_approve = false;
                this.loading_authorize_reject = false;
                this.service_name = "";
            });
    }

    unregisterDevice() {
        this.loading_unregister = true;
        axios.delete(API_BASE_URL + "/api/v2/device/no.digdir.minid.authenticator.dev", {headers: {Authorization: `AppToken ${this.getFirebaseToken()}`}})
            .then((response) => {
                this.addLog("Unregister device status: " + response.status, {});
            })
            .catch(err => {
                this.logError("Unregistering error", err);
                return Promise.reject(err.response);
            })
            .finally(() => {
                this.loading_unregister = false
            });

    }

    appOnboardingFinalize() {
        const data = new OnboardingAppFinalize(
            {
                person_identifier: this.person_identifier,
                otc: this.otc,
                token: this.getFirebaseToken()
            }
        );

        this.loading_app_finalize = true;

        return axios.post(API_BASE_URL + "/api/onboarding/finalize", data)
            .then((response) => {
                this.addLog("Finalize status: "+ response.status, {});
                this.rootStore.messageStore.initWebsocketClient(this.getFirebaseToken());
            })
            .catch(err => {
                this.logError("Onboarding App Finalize error", err);
                this.finalize_status = status.FAILED;
                return Promise.reject(err.response);
            })
            .then(() => {
                this.finalize_status = status.OK
            })
            .finally(() => {
                this.loading_app_finalize = false
            });

    }

    appOnboardingContinue(loginKey) {
        const data = new OnboardingAppContinue(
            {
                token: this.getFirebaseToken(),
                login_key: loginKey
            }
        );
        axios.post(API_BASE_URL + "/api/onboarding/continue", data)
            .then((response) => {
                this.addLog("Continue status: " + response.status, {});
                if (response.data.two_factor_method === "PIN") {
                    this.two_factor_method = "PIN";
                    this.pin_code_index = response.data.pin_index;
                } else {
                    this.two_factor_method = "SMS";
                    this.pin_code_index = "";
                }
            })
            .catch(err => {
                this.logError("Onboarding App Continue error", err);
                this.continue_status = status.FAILED;
                return Promise.reject(err.response);
            })
            .then(() => {
                this.continue_status = status.OK
            });
    }

    appOnboardingStart() {

        this.start_status = status.UNKNOWN;
        this.continue_status = status.UNKNOWN;
        this.finalize_status = status.UNKNOWN;

        this.loading_app_start = true;

        const data = new OnboardingAppStart({
            person_identifier: this.person_identifier,
            password: this.password,
            token: this.getFirebaseToken()
        })

        return axios.post(API_BASE_URL + "/api/onboarding/start", data)
            .then((response) => {
                this.start_status = status.OK
                this.addLog("Start status: " + response.status, {});
            })
            .catch(err => {
                this.logError("Onboarding App Start error", err);
                this.start_status = status.FAILED;
                return Promise.reject(err.response);
            })
            .finally(() => {
                this.loading_app_start = false
            });

    }

    appOnboardingContinueRetry() {
        const data = new OnboardingAppContinue(
            {
                token: this.getFirebaseToken(),
                login_key: this.login_key,
                mfaMethod: "EMAIL"
            }
        );
        axios.post(API_BASE_URL + "/api/onboarding/continue-retry", data)
            .then((response) => {
                this.addLog("Continue status: " + response.status, {});
                if (response.data.two_factor_method === "EMAIL") {
                    this.two_factor_method = "EMAIL";
                }
            })
            .catch(err => {
                this.logError("Onboarding App ContinueAlt error", err);
                this.continue_status = status.FAILED;
                return Promise.reject(err.response);
            })
            .then(() => {
                this.continue_status = status.OK
            });
    }

    codeOnboardingStart() {
        this.start_code_status = status.UNKNOWN;
        this.finalize_code_status = status.UNKNOWN;
        this.loading_code_start = true;

        const data = new OnboardingCodeStart({
            code: this.code,
            token: this.getFirebaseToken()
        })

        return axios.post(API_BASE_URL + "/api/onboarding/code/start", data)
            .then((response) => {
                this.start_code_status = status.OK
                this.addLog("Start code status: " + response.status, {});
            })
            .catch(err => {
                this.logError("Onboarding Code Start error", err);
                this.start_code_status = status.FAILED;
                return Promise.reject(err.response);
            })
            .finally(() => {
                this.loading_code_start = false
            });

    }


    codeOnboardingFinalize() {
        const data = new OnboardingCodeFinalize(
            {
                person_identifier: this.person_identifier,
                login_key: this.login_key,
                token: this.getFirebaseToken(),
                email: this.email,
                mobile: this.mobile,
                password: this.password
            }
        );

        this.loading_code_finalize = true;

        return axios.post(API_BASE_URL + "/api/onboarding/code/finalize", data)
            .then((response) => {
                this.addLog("Finalize status: "+ response.status, {});
                this.rootStore.messageStore.initWebsocketClient(this.getFirebaseToken());
            })
            .catch(err => {
                this.logError("Onboarding Code Finalize error", err);
                this.finalize_code_status = status.FAILED;
                return Promise.reject(err.response);
            })
            .then(() => {
                this.finalize_code_status = status.OK
            })
            .finally(() => {
                this.loading_code_finalize = false
            });

    }


    newPasswordStart() {
        this.start_new_password_status = status.UNKNOWN;
        this.continue_new_password_status = status.UNKNOWN;
        this.finalize_new_password_status = status.UNKNOWN;
        this.loading_new_password_start = true;
        this.loading_new_password_continue = false
        this.loading_new_password_finalize = false

        const data = new NewPasswordStart({
            pid: this.person_identifier,
            token: this.getFirebaseToken()
        })

        return axios.post(API_BASE_URL + "/api/user/newpassword/start", data)
            .then((response) => {
                this.start_new_password_status = status.OK
                this.addLog("Start new_password status: " + response.status, {});
            })
            .catch(err => {
                this.logError("New Password Start error", err);
                this.start_new_password_status = status.FAILED;
                return Promise.reject(err.response);
            })
            .finally(() => {
                this.loading_new_password_start = false
            });

    }


    newPasswordContinue() {
        this.loading_new_password_continue = true

        const data = new NewPasswordContinue(
            {
                token: this.getFirebaseToken(),
                login_key: this.login_key,
                os: "Android",
                otc: this.otc
            }
        );
        axios.post(API_BASE_URL + "/api/user/newpassword/continue", data)
            .then((response) => {
                this.addLog("Continue status: " + response.status, {});
                this.continue_new_password_status = status.OK;
            })
            .catch(err => {
                this.logError("New Password Continue error", err);
                this.continue_new_password_status = status.FAILED;
                return Promise.reject(err.response);
            })
            .then(() => {
                this.loading_new_password_continue = false
            });
    }


    newPasswordFinalize() {
        this.loading_new_password_finalize = true

        const data = new NewPasswordFinalize(
            {
                token: this.getFirebaseToken(),
                os: "Android",
                newPassword: this.password
            }
        );

        return axios.post(API_BASE_URL + "/api/user/newpassword/finalize", data)
            .then((response) => {
                this.addLog("Finalize status: "+ response.status, {});
            })
            .catch(err => {
                this.logError("New Password Finalize error", err);
                this.finalize_new_password_status = status.FAILED;
                return Promise.reject(err.response);
            })
            .then(() => {
                this.finalize_new_password_status = status.OK
            })
            .finally(() => {
                this.loading_new_password_finalize = false
            });

    }

    orderStart() {
        this.start_order_status = status.UNKNOWN;
        this.finalize_order_status = status.UNKNOWN;
        this.loading_order_start = true;

        const data = new OrderStart({
            person_identifier: this.person_identifier,
            token: this.getFirebaseToken()
        })

        return axios.post(API_BASE_URL + "/api/onboarding/order/start", data)
            .then((response) => {
                this.start_order_status = status.OK
                this.addLog("Start user state check status: " + response.status, {});
            })
            .catch(err => {
                this.logError("Start user state check error", err);
                this.start_order_status = status.FAILED;
                this.loading_order_start = false;
                return Promise.reject(err.response);
            });
    }

    orderFinalize() {
        const data = new OrderFinalize(
            {
                token: this.getFirebaseToken(),
                login_key: this.login_key,
                os: "Android",
                language: "nb"
            }
        );

        return axios.post(API_BASE_URL + "/api/onboarding/order/finalize", data)
            .then((response) => {
                this.addLog("Finalize status: "+ response.status, {});
            })
            .catch(err => {
                this.logError("Order Finalize error", err);
                this.finalize_order_status = status.FAILED;
                return Promise.reject(err.response);
            })
            .then((response) => {
                this.activation_code = response.data.code;
                this.finalize_order_status = status.OK
            })
            .finally(() => {
                this.loading_order_start = false;
            });

    }

    directOnboardingStart() {
        this.start_direct_onboarding_status = status.UNKNOWN;
        this.continue_direct_onboarding_status = status.UNKNOWN;
        this.finalize_direct_onboarding_status = status.UNKNOWN;
        this.loading_direct_onboarding_start = true;
        this.loading_direct_onboarding_continue = false
        this.loading_direct_onboarding_finalize = false

        const data = new DirectOnboardingStart({
            code: this.code,
            token: this.getFirebaseToken()
        })

        return axios.post(API_BASE_URL + "/api/onboarding/direct/start", data)
            .then((response) => {
                this.start_direct_onboarding_status = status.OK
                this.addLog("Start direct onboarding status: " + response.status, {});
            })
            .catch(err => {
                this.logError("Direct Onboarding Start error", err);
                this.start_direct_onboarding_status = status.FAILED;
                return Promise.reject(err.response);
            })
            .finally(() => {
                this.loading_direct_onboarding_start = false
            });

    }

    directOnboardingContinue(loginKey) {
        const data = new DirectOnboardingContinue(
            {
                token: this.getFirebaseToken(),
                login_key: loginKey
            }
        );
        axios.post(API_BASE_URL + "/api/onboarding/direct/continue", data)
            .then((response) => {
                this.addLog("Continue direct onboarding status: " + response.status, {});
                this.mobile = response.data.mobile;
                this.email = response.data.email;
            })
            .catch(err => {
                this.logError("Direct Onboarding Continue error", err);
                this.continue_direct_onboarding_status = status.FAILED;
                return Promise.reject(err.response);
            })
            .then(() => {
                this.continue_direct_onboarding_status = status.OK
            });
    }

    directOnboardingFinalize() {
        const data = new DirectOnboardingFinalize(
            {
                password: this.password,
                mobile: this.mobile,
                email: this.email,
                token: this.getFirebaseToken()
            }
        );

        this.loading_direct_onboarding_finalize = true;

        return axios.post(API_BASE_URL + "/api/onboarding/direct/finalize", data)
            .then((response) => {
                this.addLog("Finalize direct onboarding status: "+ response.status, {});
            })
            .catch(err => {
                this.logError("Direct Onboarding Finalize error", err);
                this.finalize_direct_onboarding_status = status.FAILED;
                return Promise.reject(err.response);
            })
            .then(() => {
                this.finalize_direct_onboarding_status = status.OK
            })
            .finally(() => {
                this.loading_direct_onboarding_finalize = false
            });

    }

    loadKrrInfo() {
        this.loading_krrinfo = true;
        this.krrinfo_status = status.UNKNOWN;

        return axios.get(API_BASE_URL + "/api/user/person", {headers: {Authorization: `AppToken ${this.getFirebaseToken()}`}})
            .then((response) => {
                this.krrinfo_status = status.OK
                this.addLog("KRR info status: " + response.status, {});
                this.krrinfo = response.data;
            })
            .catch(err => {
                this.logError("KRR info error", err);
                this.krrinfo_status = status.FAILED;
                return Promise.reject(err.response);
            })
            .finally(() => {
                this.loading_krrinfo = false
            });
    }



    checkStateStart() {
        this.state_check_start_status = status.UNKNOWN;
        this.state_check_finalize_status = status.UNKNOWN;
        this.user_state = "";
        this.loading_state_check_start = true;

        const data = new CheckStateStart({
            person_identifier: this.person_identifier,
            token: this.getFirebaseToken()
        })

        return axios.post(API_BASE_URL + "/api/user/state/start", data)
            .then((response) => {
                this.state_check_start_status = status.OK
                this.addLog("Start user state check status: " + response.status, {});
            })
            .catch(err => {
                this.logError("Start user state check error", err);
                this.state_check_start_status = status.FAILED;
                this.loading_state_check_start = false;
                return Promise.reject(err.response);
            });

    }


    checkStateFinalize(loginKey) {
        const data = new CheckStateFinalize(
            {
                login_key: loginKey,
                token: this.getFirebaseToken(),
            }
        );

        return axios.post(API_BASE_URL + "/api/user/state/finalize", data)
            .then((response) => {
                this.addLog("Finalize user state check status: "+ response.status, response.data);
                this.user_state = response.data.user_state;
            })
            .catch(err => {
                this.logError("User state check finalize error", err);
                this.state_check_finalize_status = status.FAILED;
                return Promise.reject(err.response);
            })
            .then(() => {
                this.state_check_finalize_status = status.OK
            })
            .finally(() => {
                this.loading_state_check_start = false
            });

    }

    checkStatus() {
        const token = this.getFirebaseToken();
        const data = {token};

        return axios.post(API_BASE_URL + "/api/v2/device/status", data)
            .then((response) => {
                this.addLog("Device check status: "+ response.status, response.data);
                this.device_status = response.data.status;
                if(this.device_status === "REGISTERED") {
                    this.rootStore.messageStore.initWebsocketClient(token);
                }
            })
            .catch(err => {
                this.logError("Device check status error", err);
                return Promise.reject(err.response);
            });
    }

    checkOrderState() {
        const token = this.getFirebaseToken();
        const data = {
            token: token
        }

        return axios.post(API_BASE_URL + "/api/v2/device/order-state", data)
            .then((response) => {
                this.addLog("Device check order-state: "+ response.status, response.data);
                this.order_state = response.data.order_state;
            })
            .catch(err => {
                this.logError("Device check status error", err);
                return Promise.reject(err.response);
            });
    }

    logError(title, error) {
        let text = title;
        if (error.response) {
            let errorResponse = error.response;

            if (errorResponse.status) {
                text += " " + errorResponse.status;
            }

            if (errorResponse.data.code) {
                text += " " + errorResponse.data.code;
            }

            if (errorResponse.data.title) {
                text += " " + errorResponse.data.title;
            }

            if (errorResponse.data.detail) {
                text += ": " + errorResponse.data.detail;
            }

            if(error.response.data) {
                errorResponse = error.response.data;
            }

            this.addLog(text, errorResponse);
        } else {
            text += " " + error.message;
            this.addLog(text, {});
        }

    }


}