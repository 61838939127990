import {inject, observer} from "mobx-react";
import React, {Component} from "react";
import TitleBox from "./TitleBox";
import {LoadingButton} from "@mui/lab";
import ReactJson from "react-json-view";

class KrrInfo extends Component {

    render() {
        const serverStore = this.props.serverStore;
        const krrinfo = this.props.serverStore.krrinfo;
        const {token} = this.props.firebaseStore;

        return (
            <TitleBox  label="Krr">
                <div style={{textAlign: "center"}}>
                    <LoadingButton id="krrinfo" loading={serverStore.loading_krrinfo} sx={{margin: "1em"}} disabled={!token} variant="contained" onClick={() => {serverStore.loadKrrInfo()}}>Fetch person from KRR-Rest</LoadingButton>
                    <br/>
                    <div style={{marginBottom: "1em", textAlign: "left"}}>
                        <ReactJson src={krrinfo} enableClipboard={false} collapsed={false}/>
                    </div>
                </div>
            </TitleBox>
        );
    }

}

export default inject('serverStore', 'firebaseStore')(observer(KrrInfo));

