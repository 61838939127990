export class DirectOnboardingStart {
    apns_sandbox = false;
    app_identifier = "no.digdir.minid.authenticator.dev";
    app_version = "2.0.5";
    os = "Android";
    os_version = "11";
    code_challenge = "qjrzSW9gMiUgpUvqgEPE4_-8swvyCtfOVvg55o5S_es";

    code;
    token;

    constructor(data) {
        if (typeof data === "undefined") {
            return;
        }
        this.code = data.code;
        this.token = data.token;
    }

}

export class DirectOnboardingContinue {
    token;
    os = "Android";
    login_key;
    mfaMethod;

    constructor(data) {
        if (typeof data === "undefined") {
            return;
        }
        this.token = data.token;
        this.login_key = data.login_key;
    }

}

export class DirectOnboardingFinalize {
    token;
    os = "Android";
    code_verifier = "M25iVXpKU3puUjFaYWg3T1NDTDQtcW1ROUY5YXlwalNoc0hhakxifmZHag";
    password;
    mobile;
    email;

    constructor(data) {
        if (typeof data === "undefined") {
            return;
        }
        this.password = data.password;
        this.mobile = data.mobile;
        this.email = data.email;
        this.token = data.token;
    }

}