import React, {Component} from 'react';
import {observer} from 'mobx-react';
import {get, set} from 'lodash';
import {TextField} from "@mui/material";
import {makeObservable, observable} from "mobx";

class SynchedInput extends Component {

    value = "";

    constructor(props) {
        super(props);
        this.inputRef = React.createRef();
        makeObservable(this, {value: observable});
    }

    componentDidMount() {
        const {source, path} = this.props;
        if (source && path) {
            this.value = get(source, path);
        }
    }

    onChange(e) {
        const {source, path, onChangeCallback} = this.props;
        this.value = e.target.value;
        set(source, path, this.value);
        if (onChangeCallback) {
            onChangeCallback(e);
        }
    }

    render() {
        const {source, path, onChangeCallback, label, ...rest} = this.props;

        return (
            <TextField inputRef={this.inputRef}
                       value={source[path]}
                       label={label}
                       onChange={this.onChange.bind(this)}
                       size="small"
                       {...rest}
            />
        );
    }

}

export default observer(SynchedInput);
