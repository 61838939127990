export class NewPasswordStart {
    apns_sandbox = false;
    app_identifier = "no.digdir.minid.authenticator.dev"
    app_version = "2.0.5";
    os = "Android";
    os_version = "11";
    code_challenge = "qjrzSW9gMiUgpUvqgEPE4_-8swvyCtfOVvg55o5S_es";

    pid;
    uuid;
    token;

    constructor(data) {
        if (typeof data === "undefined") {
            return;
        }
        this.pid = data.pid;
        this.uuid = data.uuid;
        this.token = data.token;
    }

}

export class NewPasswordContinue {
    token;
    os = "Android";
    login_key;
    otc;

    constructor(data) {
        if (typeof data === "undefined") {
            return;
        }
        this.token = data.token;
        this.os = data.os;
        this.login_key = data.login_key;
        this.otc = data.otc;
    }
}

export class NewPasswordFinalize {
    token;
    os = "Android";
    code_verifier = "M25iVXpKU3puUjFaYWg3T1NDTDQtcW1ROUY5YXlwalNoc0hhakxifmZHag";
    newPassword;

    constructor(data) {
        if (typeof data === "undefined") {
            return;
        }
        this.token = data.token;
        this.os = data.os;
        this.otc = data.otc;
        this.newPassword = data.newPassword;
    }
}
