import {inject, observer} from "mobx-react";
import React, {Component} from "react";
import TitleBox from "./TitleBox";
import DigdirForm from "./DigdirForm";
import SynchedInput from "./SynchedInput";
import CircleIcon from "@mui/icons-material/Circle";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorIcon from "@mui/icons-material/Error";
import {LoadingButton} from "@mui/lab";
import styled from "@emotion/styled";

const WrappingGrid = styled('div')(
    ({ theme }) => `
        display: grid;
        grid-template-columns: 5fr 1fr;
        row-gap: 0.5em;
        align-items: center;
`,);

const InnerGrid = styled('div')(
    ({ theme }) => `
        display: grid;
        grid-template-columns: 4fr 1fr;
        grid-template-rows: auto;
        column-gap: 0.5em;
        align-items: center;
`,);

const InnerGrid2 = styled('div')(
    ({ theme }) => `
        display: grid;
        grid-template-columns: 2fr 2fr 1fr;
        grid-template-rows: auto;
        column-gap: 0.5em;
        row-gap: 0.5em;
        align-items: center;
`,);

const StatusDiv = styled('div')(
    ({ theme }) => `
        display: grid;
        grid-column: 2/2;
        grid-row: span 2;
        margin-left: 0.5em;        
        grid-template-columns: 1fr 1fr;
        grid-template-rows: auto;
        align-items: center;
`,);



class OnboardingCode extends Component {

    onSubmitOnboardStart() {
        this.props.serverStore.codeOnboardingStart();
    }

    onSubmitOnboardFinalize() {
        this.props.serverStore.codeOnboardingFinalize();
    }

    getStatusIcon(status) {
        return (
            <span >
            {(status === "unknown")
                ? <CircleIcon color="primary" sx={{verticalAlign: "middle"}} />
                : (status === "ok")
                    ? <CheckCircleIcon color="success" sx={{verticalAlign: "middle"}} />
                    : <ErrorIcon color="error" sx={{verticalAlign: "middle"}} />
            }
            </span>
        );
    }

    render() {
        const {serverStore} = this.props;
        const {token} = this.props.firebaseStore;

        const startStatusIcon = this.getStatusIcon(serverStore.start_code_status);
        const finalizeStatusIcon = this.getStatusIcon(serverStore.finalize_code_status);

        return (
            <TitleBox label="Onboarding activation code">

                <WrappingGrid>

                    <DigdirForm id="onboard-code-start-form" onSubmitCallback={this.onSubmitOnboardStart.bind(this)}>
                        <InnerGrid>
                            <SynchedInput id="activation-code" source={serverStore} path="code" required label="Activation code"/>
                            <LoadingButton id="submit-code" loading={serverStore.loading_code_start} variant="contained" disabled={!token} label="Submit" form="onboard-code-start-form" type="submit" >Submit</LoadingButton>
                        </InnerGrid>
                    </DigdirForm>

                    <StatusDiv>
                        <div id="start-status">start:</div> <div>{startStatusIcon}</div>
                        <div id="finalize-status">finalize: </div> <div>{finalizeStatusIcon}</div>
                    </StatusDiv>


                    <DigdirForm id="onboard-code-finalize-form" onSubmitCallback={this.onSubmitOnboardFinalize.bind(this)}>
                        <InnerGrid2>
                            <SynchedInput id="code-person-identifier" source={serverStore} path="person_identifier" inputProps={{inputMode: 'numeric', pattern: '[0-9]{11}'}} required label="Person identifier"/>
                            <SynchedInput id="code-password" source={serverStore} path="password" required label="Password"/>
                            <div></div>
                            <SynchedInput id="code-email" source={serverStore} path="email" required label="Email"/>
                            <SynchedInput id="code-mobile" source={serverStore} path="mobile" required label="Mobile number"/>
                            <LoadingButton id="submit-code-finalize" loading={serverStore.loading_code_finalize} variant="contained"  label="Submit" form="onboard-code-finalize-form" type="submit" disabled={(serverStore.start_code_status !== "ok")}>Submit</LoadingButton>
                        </InnerGrid2>
                    </DigdirForm>


                </WrappingGrid>

            </TitleBox>
        );
    }

}

export default inject('serverStore', 'firebaseStore')(observer(OnboardingCode));
