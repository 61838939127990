import {inject, observer} from "mobx-react";
import React, {Component} from "react";
import ReactJson from 'react-json-view'
import TitleBox from "./TitleBox";


class CommsLog extends Component {

    render() {

        const commslog = this.props.serverStore.commslog.slice();

        return (
            <TitleBox label="Communication log">
                {commslog.map(({title, json}, index) => (
                    <div style={{marginBottom: "1em"}} key={index}>
                        <span>{title}</span>
                        <ReactJson src={json} collapsed={true} enableClipboard={false}/>
                    </div>
                ))}
            </TitleBox>
        );
    }
}

export default inject('serverStore')(observer(CommsLog));

