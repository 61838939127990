export class OnboardingAppStart {

        apns_sandbox = false;
        app_identifier = "no.digdir.minid.authenticator.dev"
        app_version = "2.0.5";
        os = "Android";
        os_version = "11";
        code_challenge = "qjrzSW9gMiUgpUvqgEPE4_-8swvyCtfOVvg55o5S_es";

        person_identifier;
        token;
        password;

    constructor(data) {
        if (typeof data === "undefined") {
            return;
        }
        this.token = data.token;
        this.person_identifier = data.person_identifier;
        this.password = data.password;
    }

}

export class OnboardingAppContinue {
    token;
    os = "Android";
    login_key;
    mfaMethod;

    constructor(data) {
        if (typeof data === "undefined") {
            return;
        }
        this.token = data.token;
        this.login_key = data.login_key;
        this.mfaMethod = data.mfaMethod;

    }
}

export class OnboardingAppFinalize {
    person_identifier;
    otc;
    token;
    os = "Android"
    code_verifier = "M25iVXpKU3puUjFaYWg3T1NDTDQtcW1ROUY5YXlwalNoc0hhakxifmZHag";
    mfa_method = "SMS";

    constructor(data) {
        if (typeof data === "undefined") {
            return;
        }
        this.person_identifier = data.person_identifier;
        this.otc = data.otc;
        this.token = data.token;
        this.mfa_method = data.mfa_method;
    }

}
