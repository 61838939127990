export class CheckStateStart {

        apns_sandbox = false;
        app_identifier = "no.digdir.minid.authenticator.dev"
        app_version = "4.0.5";
        os = "Android";
        os_version = "11";
        code_challenge = "qjrzSW9gMiUgpUvqgEPE4_-8swvyCtfOVvg55o5S_es";

        person_identifier;
        token;

    constructor(data) {
        if (typeof data === "undefined") {
            return;
        }
        this.token = data.token;
        this.person_identifier = data.person_identifier;
    }

}

export class CheckStateFinalize {
    os = "Android";
    code_verifier = "M25iVXpKU3puUjFaYWg3T1NDTDQtcW1ROUY5YXlwalNoc0hhakxifmZHag";

    token;
    login_key;

    constructor(data) {
        if (typeof data === "undefined") {
            return;
        }
        this.token = data.token;
        this.login_key = data.login_key;
    }
}
