import {getMessaging, getToken, onMessage} from "firebase/messaging";
import {makeObservable, observable} from "mobx";
import {initializeApp} from 'firebase/app'
import {isEmpty} from "lodash";


const CryptoJS = require("crypto-js/core");
CryptoJS.AES = require("crypto-js/aes");

export default class FirebaseStore {
    token = "";
    passkey = "";
    wrongPasskey = false;

    vapid = "";
    vapidCipher = "U2FsdGVkX19hz9uNqTyDgTdfGXWoWN05egL8ZgqRxMHgH/jtjZz5ZP8/aYWyOnsgh7GpX5HwvDPuxhv/BUv9Hs1MB5msabeNn5yuLUot4xJTBW7BAE3JigaeDqbWTnakKpQGIsQNQbPaUEoR2kX7nw==";

    firebaseConfig = {};
    firebaseConfigCipher = "U2FsdGVkX1+5ALxYe3AfFsWdal+KRhKvGFTCgEmLrsCy2YH+JPDjxEZvkcgq7n3WVaX+4ZL0fF+PTyxDuw+rhasraqLLhkow7FT7TuK+QmO0rvnkqKqOrfY3umKTWv7hMx/+M5N75NRHtGajSZ8jUOBEZ28CNyxBHoze2QUb6O7ZWsFqMFR8ZuCU4nX5HN2/hGMy+yBHDlCJa8RUAVahP7HU6dSprR8BUSETRr9hnsIMMCKEcTs7G8BdIhGQkmbD7iPQJzleS5T1QmhuRcJp/XoapAxMawvI+hW2f/iEQ/ENycz4VmhzZ4ggLNmgpEUu3HnYtoaKy0Ihf5OG7aSiOZ9cYoEUbEQZBBRqYZGIM0Rp6UqTMLQ2AXOIEZ+i808nxsEvJ8ifmBvSZ8GcMiojiro8PXDNxys4pwhKX8WdqHOUrCkHaY27YzNx1ehAr+c+";

    constructor(rootStore) {
        this.rootStore = rootStore;
        makeObservable(this,
            {
                token: observable,
                passkey: observable,
                wrongPasskey: observable,
                vapid: observable,
            });

        // uncomment to generate cipher text from config
        /*
        this.encryptConfig(
            {
                apiKey: "",
                authDomain: "",
                projectId: "",
                storageBucket: "",
                messagingSenderId: "",
                appId: "",
                measurementId: ""
            },
            "phrase");
        */

        // try to restore vapid & config from localStorage;
        this.vapid = localStorage.getItem("vapid") || "";
        let firebaseConfigString = localStorage.getItem("firebaseConfig");
        if (this.vapid && firebaseConfigString) {
            this.firebaseConfig = JSON.parse(firebaseConfigString);
            this.installServiceWorker();
        }
    }

    installServiceWorker() {
        navigator.serviceWorker.register("/firebase-messaging-sw.js")
            .then((registration) => {
                navigator.serviceWorker.ready
                    .then(reg => {
                        reg.active.postMessage(this.firebaseConfig);
                    })
                    .then(() => {
                        this.initFirebase(registration);
                    });
            })
            .catch((error) =>   {
                console.log('*** Service worker registration failed:', error);
                console.log('*** Try starting chrome with flags: --incognito --ignore-certificate-errors --ignore-urlfetcher-cert-requests --user-data-dir=/tmp/foo')
            });
    }

    initFirebase(registration) {
        initializeApp(this.firebaseConfig);
        const messaging =  getMessaging();

        getToken(messaging, {vapidKey: this.vapid, serviceWorkerRegistration: registration})
            .then((currentToken) => {
                if (currentToken) {
                    this.token = currentToken;
                    this.rootStore.serverStore.checkStatus();
                    this.rootStore.serverStore.checkOrderState();
                } else {
                    console.log('No registration token available. Request permission to generate one.');
                }
            })
            .then(() => {
                // add listener
                onMessage(messaging, (payload) => {
                    console.log("Notification payload: ", payload.data);
                    this.rootStore.serverStore.onMessageReceived(payload.data, "FCM Notification");
                });
            })
            .catch((err) => {
                console.log('An error occurred while retrieving token. ', err);
            });
    }

    encryptConfig(cfg, key) {
        const encodedConfig = CryptoJS.AES.encrypt(JSON.stringify(cfg), key);
        console.log("*** encodedConfig: " + encodedConfig);
    }

    decryptConfig() {
        this.wrongPasskey = false;
        try {
            if (!this.vapid) {
                const bytes = CryptoJS.AES.decrypt(this.vapidCipher, this.passkey);
                this.vapid = bytes.toString(CryptoJS.enc.Utf8);
                localStorage.setItem("vapid", this.vapid);
            }

            if (isEmpty(this.firebaseConfig)) {
                const bytes = CryptoJS.AES.decrypt(this.firebaseConfigCipher, this.passkey);
                let firebaseConfigString = bytes.toString(CryptoJS.enc.Utf8);
                this.firebaseConfig = JSON.parse(firebaseConfigString);
                localStorage.setItem("firebaseConfig", firebaseConfigString);
            }
            this.installServiceWorker();
        } catch (error) {
            this.wrongPasskey = true;
            setTimeout(() => { this.wrongPasskey = false; }, 1000);
        }
    }

}