import {inject, observer} from "mobx-react";
import React, {Component} from "react";
import TitleBox from "./TitleBox";
import DigdirForm from "./DigdirForm";
import SynchedInput from "./SynchedInput";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import CircleIcon from '@mui/icons-material/Circle';
import {LoadingButton} from "@mui/lab";
import styled from "@emotion/styled";

const WrappingGrid = styled('div')(
    ({ theme }) => `
        display: grid;
        grid-template-columns: 5fr 1fr;
        row-gap: 0.5em;
        align-items: center;
`,);

const InnerGrid = styled('div')(
    ({ theme }) => `
        display: grid;
        grid-template-columns: 2fr 2fr 1fr;
        grid-template-rows: auto;
        column-gap: 0.5em;
        align-items: center;
`,);


const StatusDiv = styled('div')(
    ({ theme }) => `
        display: grid;
        grid-column: 2/2;
        grid-row: span 2;
        margin-left: 0.5em;        
        grid-template-columns: 1fr 1fr;
        grid-template-rows: auto;
        align-items: center;
`,);


const UserStateText = styled('div')(
    ({ theme }) => `
        font-family: Roboto, Helvetica, Arial, sans-serif;
        font-size: 16px;
        font-stretch: 100%;
        font-style: normal;
        font-weight: 400;
        color: grey;
        margin-left: 1em;
        margin-top: 0.5em;
`,);



class UserStateCheck extends Component {


    onSubmitStatusCheckStart() {
        this.props.serverStore.checkStateStart();
    }

    getStatusIcon(status) {
        return (
            <span >
            {(status === "unknown")
                ? <CircleIcon color="primary" sx={{verticalAlign: "middle"}} />
                : (status === "ok")
                ? <CheckCircleIcon color="success" sx={{verticalAlign: "middle"}} />
                : <ErrorIcon color="error" sx={{verticalAlign: "middle"}} />
            }
            </span>
        );
    }


    render() {
        const {serverStore} = this.props;
        const {token} = this.props.firebaseStore;

        const startStatusIcon = this.getStatusIcon(serverStore.state_check_start_status);
        const finalizeStatusIcon = this.getStatusIcon(serverStore.state_check_finalize_status);

        return (
            <TitleBox label="User status check">

                <WrappingGrid>

                    <DigdirForm id="state-check-start-form" onSubmitCallback={this.onSubmitStatusCheckStart.bind(this)}>
                        <InnerGrid>
                            <SynchedInput id="state-check-person-identifier" source={serverStore} path="person_identifier" inputProps={{inputMode: 'numeric', pattern: '[0-9]*'}} required label="Person identifier"/>
                            <UserStateText>
                                User State: {serverStore.user_state && <span id="user_state">{serverStore.user_state}</span>}
                            </UserStateText>
                            <LoadingButton id="submit-state-check" loading={serverStore.loading_state_check_start} variant="contained" disabled={!token} label="Submit" form="state-check-start-form" type="submit">Submit</LoadingButton>

                        </InnerGrid>
                    </DigdirForm>

                    <StatusDiv>
                        <div id="state-check-start-status">start:</div> <div>{startStatusIcon}</div>
                        <div id="state-check-finalize-status">finalize: </div> <div>{finalizeStatusIcon}</div>
                    </StatusDiv>

                </WrappingGrid>

            </TitleBox>
        );
    }

}

export default inject('serverStore', 'firebaseStore')(observer(UserStateCheck));

