import React from 'react';
import ReactDOM from 'react-dom';
import axios from "axios";
import {configure} from "mobx"
import {Provider} from "mobx-react";
import RootStore from "./stores/RootStore";
import createCache from '@emotion/cache';
import {CacheProvider} from '@emotion/react';
import App from "./App";

// default options for axios
axios.defaults.withCredentials = true;
axios.defaults.headers.common["X-MinID-App-OS"] = "Android";
axios.defaults.headers.common["X-MinID-App-Version"] = "2.2.0";

const cache = createCache({
    key: 'jss-class-prefix-key',
    nonce: document.querySelector('meta[property="csp-nonce"]')?.content || '',
    prepend: true
});

// mobx
configure({enforceActions: "never"});

ReactDOM.render(
    <React.StrictMode>
        <Provider {...new RootStore()}>
            <CacheProvider value={cache}>
                <App />
            </CacheProvider>
        </Provider>
    </React.StrictMode>,
    document.getElementById('root')
);