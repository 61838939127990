import UIStore from "./UIStore";
import ServerStore from "./ServerStore";
import FirebaseStore from "./FirebaseStore";
import MessageStore from "./MessageStore";

export default class RootStore {


    constructor() {
        this.serverStore = new ServerStore(this);
        this.uiStore = new UIStore(this);
        this.firebaseStore = new FirebaseStore(this);
        this.messageStore = new MessageStore(this);
    }

}