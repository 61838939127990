export class OnboardingCodeStart {
    apns_sandbox = false;
    app_identifier = "no.digdir.minid.authenticator.dev"
    app_version = "2.0.5";
    os = "Android";
    os_version = "11";
    code_challenge = "qjrzSW9gMiUgpUvqgEPE4_-8swvyCtfOVvg55o5S_es";

    token;
    code;

    constructor(data) {
        if (typeof data === "undefined") {
            return;
        }
        this.token = data.token;
        this.code = data.code;
    }

}

export class OnboardingCodeFinalize {

    code_verifier = "M25iVXpKU3puUjFaYWg3T1NDTDQtcW1ROUY5YXlwalNoc0hhakxifmZHag";
    os = "Android";
    person_identifier;
    login_key;
    token;
    email;
    password;
    mobile;

    constructor(data) {
        if (typeof data === "undefined") {
            return;
        }
        this.token = data.token;
        this.login_key = data.login_key;
        this.person_identifier = data.person_identifier;
        this.email = data.email;
        this.password = data.password;
        this.mobile = data.mobile;
    }


}