import {inject, observer} from "mobx-react";
import React, {Component} from "react";
import TitleBox from "./TitleBox";
import {LoadingButton} from "@mui/lab";

class Unregister extends Component {

    render() {

        const {serverStore} = this.props;
        const {token} = this.props.firebaseStore;


        return (
            <TitleBox  label="Unregister">
                <div style={{textAlign: "center"}}>
                    <LoadingButton id="unregister" loading={serverStore.loading_unregister} sx={{margin: "1em"}} disabled={!token} variant="contained" onClick={() => {serverStore.unregisterDevice()}}>Unregister</LoadingButton>
                </div>
            </TitleBox>
        );
    }

}

export default inject('serverStore', 'firebaseStore')(observer(Unregister));

