import {observer} from "mobx-react";
import React, {Component} from "react";

class TitleBox extends Component {

    render() {
        const {label, children} = this.props;

        return (
            <div style={{
                position: "relative",
                border: "1px solid #ddd",
                padding: "1em",
                margin: "1em",
                borderRadius: "4px"
            }}>
                <h1 style={{
                    position: "absolute",
                    top: 0,
                    fontSize: "18px",
                    lineHeight: 1,
                    margin: "-9px 0 0",
                    background: "#fff",
                    padding: "0 3px"
                }}>
                    {label}
                </h1>
                {children}
            </div>

        );
    }

}

export default observer(TitleBox);
