export class OrderStart {
    apns_sandbox = false;
    app_identifier = "no.digdir.minid.authenticator.dev"
    app_version = "2.0.5";
    os = "Android";
    os_version = "11";
    code_challenge = "qjrzSW9gMiUgpUvqgEPE4_-8swvyCtfOVvg55o5S_es";

    person_identifier;
    token;

    constructor(data) {
        if (typeof data === "undefined") {
            return;
        }
        this.person_identifier = data.person_identifier;
        this.token = data.token;
    }
}

export class OrderFinalize {
    token;
    os = "Android";
    code_verifier = "M25iVXpKU3puUjFaYWg3T1NDTDQtcW1ROUY5YXlwalNoc0hhakxifmZHag";
    login_key;
    language;

    constructor(data) {
        if (typeof data === "undefined") {
            return;
        }
        this.token = data.token;
        this.os = data.os;
        this.login_key = data.login_key;
        this.language = data.language;
    }
}