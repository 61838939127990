import {Client} from "@stomp/stompjs";

export default class MessageStore {

    client = null;

    constructor(rootStore) {
        this.rootStore = rootStore;
    }

    initWebsocketClient(fcmToken) {

        if(this.client) {
            return;
        }

        const proto = (window.location.protocol === "http:" ? "ws:" : "wss:");
        const hostname = window.location.hostname ;
        const port = (process.env.NODE_ENV === "development" ? ":8080" : (window.location.port ? ":" + window.location.port : "")) ;

        this.client = new Client({
            brokerURL: proto + "//" + hostname + port + "/api/app/ws",
            debug: function (str) {
                console.log("Websocket debug:", str);
            },
            reconnectDelay: 60000,
            heartbeatIncoming: 25000,
            heartbeatOutgoing: 25000,
            connectHeaders: {
                Authorization: "AppToken " + fcmToken
            },
            onConnect: () => {
                this.rootStore.serverStore.addLog("Websocket CONNECTED");
                this.client.subscribe('/user/queue/notifications', message => {
                    const body = JSON.parse(message.body);
                    console.log("Websocket payload:", body);
                    this.rootStore.serverStore.onMessageReceived(body, "Websocket")
                });
            },
            onStompError: (frame) => {
                // Will be invoked in case of error encountered at Broker
                // Bad login/passcode typically will cause an error
                // Complaint brokers will set `message` header with a brief message. Body may contain details.
                // Compliant brokers will terminate the connection after any error
                console.log('Broker reported error: ' + frame.headers['message']);
                console.log('Additional details: ' + frame.body);
            }
        });

        console.log("Trying to connect websocket at " + this.client.brokerURL);
        this.client.activate();
    }

}